import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext
} from "react";
import { createPortal } from "react-dom";
import { countries as countriesConfig } from "resources/countries-config.json";
import useUserPreferences from "hooks/useUserPreferences";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { processEnvServer } from "hooks/useSsrHooks";
import { CountrySiteContext } from "customProviders/CountrySiteProvider";
import useSite from "hooks/useSite";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import memoize from "@RHCommerceDev/utils/memoize";
import { usePageContentNoProxy } from "custom-providers/LocationProvider";

export type GlobalCountrySelectorProps = {
  isDesktop?: boolean;
  color?: string;
  pageContent?: any;
};

const GlobalCountrySelector: FC<GlobalCountrySelectorProps> = ({
  color,
  isDesktop,
  ...rest
}) => {
  const env = useEnv();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const countryFromUrl = getCountryFromUrl();
  const { setCookieWrapper } = useCookiesWithPermission();
  const initialCountry = countriesConfig[countryFromUrl].label.replace(
    /\s*\(.*\)$/,
    ""
  );
  const initialCountryEN = countriesConfig[countryFromUrl].labelEN.replace(
    /\s*\(.*\)$/,
    ""
  );
  const [selectedCountry, setSelectedCountry] = useState(initialCountryEN);
  const portalContainerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [dropdownStyles, setDropdownStyles] = useState<React.CSSProperties>({});
  const dropDownRef = useRef<HTMLDivElement | null>(null);
  const { setCountry, setLanguage, setMeasurement, handleSaveCookies } =
    useUserPreferences();
  const { setCountrySelector } = useContext(CountrySiteContext);
  const brand = useSite();
  useEffect(() => {
    const portalContainer = document.createElement("div");
    document.body.appendChild(portalContainer);
    portalContainerRef.current = portalContainer;

    return () => {
      if (portalContainerRef.current) {
        document.body.removeChild(portalContainerRef.current);
      }
    };
  }, []);

  const pageContent =
    rest?.pageContent ?? usePageContentNoProxy()?.pageContent?.globalHeader;

  const countries = useMemo(() => {
    // Extract countries from pageContent
    const dynamicCountries = Object.entries(
      pageContent?.countrySelectorDialog?.countries || {}
    ).map(([value, label]) => {
      // Assert that label is a string
      const cleanedLabel = (label as string).replace(/\s*\(.*\)$/, "");
      return { label: cleanedLabel, value };
    });
    if (brand === "BC" || brand === "TN") {
      return dynamicCountries.filter(
        country => country.value === "US" || country.value === "CA"
      );
    }
    // Check for feature flag and add France if enabled
    if (yn(env.FEATURE_EU_EXPANSION_FR)) {
      const cleanedLabel = "France (€)".replace(/\s*\(.*\)$/, "");
      dynamicCountries.push({ label: cleanedLabel, value: "FR" });
    }

    // Sort the countries, ensuring "United States" is first
    dynamicCountries.sort((a, b) => {
      if (a.label === "United States") return -1;
      if (b.label === "United States") return 1;
      return a.label.localeCompare(b.label);
    });

    return dynamicCountries;
  }, [pageContent, env.FEATURE_EU_EXPANSION_FR]);

  const handleClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownStyles({
        position: "absolute",
        top: `${rect.bottom + window.scrollY - 3}px`,
        right: `${rect.left + window.scrollX - 12}px`,
        width: `auto`,
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",
        background: "var(--ReimagineWarm-RH-Buff, #F9F7F4)"
      });
    }
    setIsDropdownOpen(!isDropdownOpen);
    setTimeout(() => {
      setIsRotated(true);
    }, 0);
  };
  const handleOnClose = () => {
    setIsDropdownOpen(false);
    setIsRotated(false);
  };

  const handleClickOutside = event => {
    if (dropDownRef.current && !dropDownRef.current?.contains(event.target)) {
      handleOnClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleCountrySelect = (country: string) => {
    setCountrySelector(true);
    const updatedCountry = countriesConfig[country].defaultValues.country;
    const updatedLanguage = countriesConfig[country].defaultValues.language;
    const updatedMeasurement =
      countriesConfig[country].defaultValues.measurement;
    if (!processEnvServer) {
      document?.body?.dispatchEvent(
        new CustomEvent("cta_click", {
          detail: {
            item: {
              fromHeader: true,
              country: updatedCountry,
              language: updatedLanguage,
              measurement: updatedMeasurement
            }
          }
        })
      );
    }
    setCountry(updatedCountry);
    setSelectedCountry(updatedCountry);
    setLanguage(updatedLanguage);
    setMeasurement(updatedMeasurement);
    handleSaveCookies(
      {
        country: updatedCountry,
        language: updatedLanguage,
        measurement: updatedMeasurement
      },
      undefined,
      undefined,
      true
    );
    setCookieWrapper("manual_country_selection", true);
    setIsDropdownOpen(!isDropdownOpen);
    setIsRotated(!isRotated);
  };

  const dropdownContent = (
    <div
      style={dropdownStyles}
      className="-mt-[34px] shadow-md z-[1300]"
      ref={dropDownRef}
    >
      {[
        selectedCountry,
        ...countries.filter(c => c.label !== initialCountryEN)
      ].map((countryObj, index) => {
        const countryValue =
          typeof countryObj === "string" ? countryObj : countryObj.value;
        const countryLabelEN =
          typeof countryObj === "string" ? countryObj : countryObj.label;
        return (
          <div
            key={index}
            className={`flex justify-between items-center pl-[16px] pr-[12px] py-[10px] cursor-pointer text-left
              text-[#898886] hover:text-[#000] text-[13px] font-normal font-primary-rhc-roman capitalize leading-[120%] tracking-[0.52px]`}
            onClick={() => {
              countryLabelEN != selectedCountry
                ? handleCountrySelect(countryValue)
                : handleOnClose();
            }}
          >
            <span className="h-4 mr-[4px]">{countryLabelEN}</span>
            {countryLabelEN === selectedCountry && (
              <div
                className={`w-4 h-4 relative top-[85%] -translate-y-[4%] transform transition-transform duration-300 ${
                  isRotated ? "rotate-180" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 6L8 10L4 6"
                    stroke="currentColor"
                    strokeWidth="0.75"
                  />
                </svg>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="relative transform translate-y-[52%]">
      <button
        ref={buttonRef}
        className="flex w-full !pt-[0px] pl-[16px] pr-[0px] pb-0 justify-end items-center"
        style={{ color: color ? color : undefined, background: "transparent" }}
      >
        <div
          onClick={handleClick}
          className={`flex justify-center text-right h-4 text-[13px] font-normal font-primary-rhc-roman capitalize leading-[120%] tracking-[0.52px] mr-[4px]`}
        >
          {selectedCountry}
        </div>

        <div
          className={`w-4 relative transform transition-transform duration-300 ${
            isRotated ? "rotate-180" : ""
          }`}
          onClick={handleClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path d="M12 6L8 10L4 6" stroke="currentColor" strokeWidth="0.75" />
          </svg>
        </div>
      </button>

      {isDropdownOpen &&
        portalContainerRef.current &&
        createPortal(dropdownContent, portalContainerRef.current)}
    </div>
  );
};

export default memoize(GlobalCountrySelector);
