import React, { useState, useEffect, useCallback } from "react";
import { Typography, Button, Grid } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import CountryPriceNotificationDialog from "dialog-country-price-notification";
import useUserContext from "hooks/useUserContext";
import { parseUserContextCookie } from "utils/parseUserContextCookie";
import useAppData from "hooks/useAppData";
import Cookies from "universal-cookie";
import EventEmitter from "utils-event-emitter";
import memoize from "utils/memoize";
import { getGlobal } from "@RHCommerceDev/utils-globals/getGlobal";
import {
  defaultCountryCodeFromPostalCode,
  trimPostalCode
} from "utils/addressUtils";
import { getCookie } from "utils/cookies";
import { useLocation } from "react-router-dom";
import { useUpdateCart } from "hooks/useUpdateCart";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { POSTAL_CODE_COOKIE } from "utils/constants";
import { useRhUserAtomValue } from "hooks/atoms";
import { useUserSessionAtomValue } from "hooks/atoms";
import { useCurrentCartId } from "@RHCommerceDev/hooks-atoms/useCurrentCartId";

const coreDomain = getGlobal("_env").ENV_DOMAIN;

const useStyles = makeStyles(() =>
  createStyles({
    button: { marginTop: "20px" },
    zipCodeButton: {
      minWidth: "185px",
      marginTop: "20px",
      alignSelf: "center"
    }
  })
);

const CountryPriceNotification = () => {
  const { loading } = useUserSessionAtomValue();
  const currentCartId = useCurrentCartId();
  const rhUser = useRhUserAtomValue();
  const [{ open, content }, setDialogState] = useState({
    open: false,
    content: ""
  });
  const [currentZip, setCurrentZip] = useState("");
  const [userContextTrigger, setUserContextTrigger] = useState(Date.now());
  const { setApp, app } = useAppData();
  const userContext = useUserContext([userContextTrigger]);
  const [initialUserContext, setInitialUserContext] = useState(userContext);
  const location = useLocation();
  const cookies = new Cookies();
  const classes = useStyles();

  // trigger to updated useUserContext
  const updateUserContextTrigger = useCallback(
    () => setUserContextTrigger(Date.now()),
    []
  );

  const { setCookieWrapper } = useCookiesWithPermission();

  const updatePostalCodeWrapper = useCallback(
    async (postalCode: string) => {
      setCookieWrapper(POSTAL_CODE_COOKIE, postalCode);

      await setApp({
        postalCode: currentZip
      });

      updateUserContextTrigger();
    },
    [currentZip, setApp, updateUserContextTrigger]
  );

  const { updateCart, loading: loadingUpdateCart } = useUpdateCart({
    callerId: "COUNTRY_PRICE_NOTIFICATION"
  });

  const Loading = loading || loadingUpdateCart;

  // check if user's origin=CA and if CA popup has NOT been selected
  useEffect(() => {
    const { country } = parseUserContextCookie(getCookie("userContext") || "");
    if (
      country === "CA" &&
      !getCookie("ca_popup_selected") &&
      !(
        window.location.hostname.includes("guesthouse") ||
        window.location.pathname.includes("guesthouse")
      )
    ) {
      setDialogState({
        open: true,
        content: "COUNTRY_CHANGE_TO_CA"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.isLoading, app?.postalCode, location]);
  // set dialog's currencyCode template if currencyCode changed
  // set initialUserContext when userContext updates
  useEffect(() => {
    const didCurrencyChange =
      userContext.currencyCode !== initialUserContext.currencyCode;
    const templateName = `CURRENCY_CHANGE_TO_${userContext.currencyCode}`;
    if (templates[templateName] && didCurrencyChange) {
      setDialogState({
        open: true,
        content: templateName
      });
    }
    setInitialUserContext(userContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContextTrigger]);

  // eventEmitter handler
  const handler = useCallback(updateContext => {
    if (updateContext) {
      updateUserContextTrigger();
    }
  }, []);
  // zipcode_change_dialog eventEmitter subscriber
  useEffect(() => {
    EventEmitter.subscribe(`zipcode_change_dialog`, handler);

    return () => {
      EventEmitter.remove(`zipcode_change_dialog`, handler);
    };
  });

  const closeDialog = useCallback(() => {
    setDialogState({
      open: false,
      content: ""
    });
  }, []);

  // "zip" param is the default country's zipcode
  const onShipToCountryClick = useCallback(
    async (zip: string) => {
      setCurrentZip(zip);
      cookies.set("ca_popup_selected", true, { domain: `.${coreDomain}` });
      if (zip) {
        updatePostalCodeWrapper(zip);
      }
      const trimmedPostalCode = trimPostalCode(zip);
      if (rhUser?.email && currentCartId) {
        await updateCart({
          postalCode: trimmedPostalCode,
          country: defaultCountryCodeFromPostalCode(trimmedPostalCode)
        });
      }
      closeDialog();
    },
    [
      closeDialog,
      cookies,
      currentCartId,
      rhUser?.email,
      updateCart,
      updatePostalCodeWrapper
    ]
  );

  const shipToCanadaCB = useCallback(
    () => onShipToCountryClick("M4P 2H4"),
    [onShipToCountryClick]
  );
  const shipToUSCB = useCallback(
    () => onShipToCountryClick("94925"),
    [onShipToCountryClick]
  );

  const templates: { [key: string]: JSX.Element } = {
    COUNTRY_CHANGE_TO_CA: (
      <>
        <Typography variant="h1">CANADIAN CUSTOMERS</Typography>
        <Typography variant="h2">SHIPPING & PRICING</Typography>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={12} sm={5} style={{ marginTop: "40px" }}>
            <Typography>
              If you are shipping to Canada, you will view prices and transact
              in Canadian dollars.
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              disabled={Loading}
              onClick={shipToCanadaCB}
            >
              SHIP TO CANADA
            </Button>
          </Grid>
          <Grid item xs={12} sm={5} style={{ marginTop: "40px" }}>
            <Typography>
              If you are shipping to the U.S., you will view prices and transact
              in U.S. dollars.
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              disabled={Loading}
              onClick={shipToUSCB}
            >
              SHIP TO U.S.
            </Button>
          </Grid>
        </Grid>
      </>
    ),
    CURRENCY_CHANGE_TO_CAD: (
      <>
        <Typography>
          The shipping address you've entered is in Canada. With your
          acceptance, the items in your cart will be updated with Canadian
          prices and your payment will be in Canadian dollars at checkout.
          Canadian prices reflect adjustments for factors including, but not
          limited to, exchange rate, duties, brokerage and operating costs. If
          you do not want to ship to Canada and pay in Canadian dollars, please
          enter a shipping address in the United States.
        </Typography>
        <Button
          className={classes.zipCodeButton}
          variant="contained"
          color="primary"
          data-testid={"currency-acceptance-modal"}
          onClick={closeDialog}
        >
          OK
        </Button>
      </>
    ),
    CURRENCY_CHANGE_TO_USD: (
      <>
        <Typography>
          The shipping address you've entered is in the US. With your
          acceptance, the items in your cart will be updated with US prices and
          your payment will be in US dollars at checkout. If you do not want to
          ship to the US and pay in US dollars, please enter a shipping address
          in Canada.
        </Typography>
        <Button
          className={classes.zipCodeButton}
          variant="contained"
          color="primary"
          onClick={closeDialog}
        >
          OK
        </Button>
      </>
    )
  };

  return (
    <>
      <CountryPriceNotificationDialog
        content={templates[content]}
        open={open}
        onClose={closeDialog}
      />
    </>
  );
};

export default memoize(CountryPriceNotification);
