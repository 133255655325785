import React from "react";
import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import cn from "classnames";
import { BREAKPOINT_LG, BREAKPOINT_SM } from "utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cellWrapper: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap"
    },
    cellContent: {
      flexWrap: "nowrap"
    },
    cellHeading: {
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "nowrap"
    },
    cellTitle: {
      fontWeight: "normal",
      fontSize: 13,
      color: "#000",
      letterSpacing: "-0.04em"
    },
    cellTextWrapper: {
      display: "flex",
      alignItems: "flex-start",
      maxWidth: "76%"
    },
    cellText: {
      fontSize: 13,
      color: "#808080",
      letterSpacing: "-0.008em",
      lineHeight: "19.5px",
      marginTop: theme.spacing(1)
    },
    rightAddonsWrapper: {
      marginLeft: theme.spacing(1.5),
      [theme.breakpoints.down(BREAKPOINT_LG)]: {
        marginLeft: theme.spacing(2)
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        marginLeft: theme.spacing(2)
      }
    }
  })
);

type CellClasses = {
  root?: string;
  heading?: string;
  title?: string;
  textWrapper?: string;
  text?: string;
  rightAddonsWrapper?: string;
};

interface CellProps {
  rightAddons?: React.ReactNode;
  bottomAddons?: React.ReactNode;
  title: string;
  text?: string;
  classes?: CellClasses;
}

export const Cell = ({
  rightAddons,
  bottomAddons,
  title,
  text,
  classes
}: CellProps) => {
  const localClasses = useStyles();

  return (
    <Grid
      container
      className={cn(localClasses.cellWrapper, classes?.root)}
      id={"component-cell"}
    >
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        item
        xs={12}
        className={cn(localClasses.cellContent, classes?.heading)}
      >
        <Grid
          justifyContent="space-between"
          direction="column"
          item
          xs={12}
          className={cn(localClasses.cellHeading, classes?.heading)}
        >
          <Typography
            variant="body1"
            className={cn(localClasses.cellTitle, classes?.title)}
          >
            {title}
          </Typography>
          {!!text && (
            <Typography
              variant="body1"
              className={cn(localClasses.cellText, classes?.text)}
            >
              {text}
            </Typography>
          )}
        </Grid>
        {!!rightAddons && (
          <Grid
            item
            className={cn(
              localClasses.rightAddonsWrapper,
              classes?.rightAddonsWrapper
            )}
          >
            {rightAddons}
          </Grid>
        )}
      </Grid>
      {bottomAddons}
    </Grid>
  );
};

export default Cell;
