import { getAEMCommonHTMLAttributes } from "aem";
import EditableContainer, {
  EditableContainerProps
} from "aem/ui/authoring/EditableContainer";
import classNames from "classnames";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, BoxProps } from ".";

const useStyles = (props: AEMBoxProps) => {
  return makeStyles(() => {
    return createStyles({
      root: {
        "& > div": {
          // FIXME: To avoid the extra div AEM adds between each children
          // Ask Adobe for a solution.
          display: props?.isInEditor ? undefined : "contents"
        }
      }
    });
  });
};

export type AEMBoxProps = BoxProps & EditableContainerProps;

export const AEMBox: React.FC<AEMBoxProps> = props => {
  const aemCommonHTMLAttributes = getAEMCommonHTMLAttributes(props);

  const { children, isInEditor, ...rest } = props;

  const classes = useStyles(props)();

  if (isInEditor) {
    return (
      <EditableContainer {...rest}>
        <div {...rest} style={{ display: "block", boxSizing: "inherit" }}>
          {children}
        </div>
      </EditableContainer>
    );
  }

  const { hideClassNames } = props;

  return (
    <Box
      {...rest}
      className={classNames(classes.root, hideClassNames)}
      extraHTMLAttributes={aemCommonHTMLAttributes}
    >
      {children}
    </Box>
  );
};

export default AEMBox;
